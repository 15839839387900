import app from './config/configApp';
import router from './routes/protectedRoute';
import store from '@/vuex/store';
import './static/css/style.css';
// Vue 3rd party plugins
import '@/core/plugins/ant-design';
import '@/core/plugins/fonts';
// import '@/core/plugins/maps';
// import '@/core/plugins/masonry';
// import '@/core/plugins/apexcharts';
import '@/core/components/custom';
import '@/core/components/style';
import VueGoogleMaps from '@fawmi/vue-google-maps'
import vClickOutside from "v-click-outside";
import axios from "axios";
import Cookies from 'js-cookie';
import { inject } from 'vue';


// console.log(Cookies.get('token'));
axios.defaults.headers.common['Authorization'] = '' + Cookies.get('token');

window.axios = axios;

app.config.productionTip = false;
app.use(store);
app.use(router);
app.use(vClickOutside);
app.use(VueGoogleMaps, {
    load: {
        libraries: ['places'],
        key: 'AIzaSyAhYZg5rq82zGh1bt8hBWs5tTSOROJcMhU',
    },
});
app.mount('#app');
