const routes = [
  {
    path: 'login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/view/authentication/SignIn.vue'),
  },
  {
    path: 'reset/:token',
    name: 'reset',
    component: () => import(/* webpackChunkName: "register" */ '@/view/authentication/Reset.vue'),
  },
  {
    path: 'forgotPassword',
    name: 'forgotPassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '@/view/authentication/ForgotPassword.vue'),
  },
  {
    path: 'otp',
    name: 'otp',
    component: () => import(/* webpackChunkName: "login" */ '@/view/authentication/Otp.vue'),
  }
];

export default routes;
